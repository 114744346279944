/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
    	  $('.toggle_trigger').each(function (i, el) {
    		  $(el).click(function () {
    			  var triggered = $(el).data('triggered');
    			  $(triggered).slideToggle();
    			  $(el).toggleClass('open');
    		  });
    	  });
    	  $('.menu-toggle').click(function () {
    		  $('.topmenu_holder').slideToggle();
    	  });
    	  if ($(window).width() > 1400 && $('.widescreen_banner_holder').length) {
    		  $('.widescreen_banner_holder .img_fill').each(function (i, el) {
    			  var banner_img_url 	= $(el).data('src');
    			  var banner_link 		= $(el).data('link');
    			  el.innerHTML 			= '<a href="' + banner_link + '" target="_blank"><img src="' + banner_img_url + '" width="100%" alt="" /></a>';
    		  });
    	  }
		    	  
    	  
    	  $('.sublevel1_trigger').each(function (i, el) {
    		  $(el).click(function () {
    			  var submenu = $(el).parent().find('.sublevel1');
    			  if ($(window).width() > 767) {
    				  var masonry_container = $('.masonry-container');
    		    	  var masonry_options = {
		        		  columnWidth: '.item',
		        		  itemSelector: '.item',
		        		  isRTL: true,
		        		  originLeft: false ,
		        		  gutter: 15
		        	  };
    				  $('.sublevel1').fadeOut();
        			  $(submenu).fadeIn(function () {
        				 masonry_container.masonry(masonry_options);
        			  });
    			  }
    			  else {
    				  $(submenu).slideToggle();
    			  }
    			  return false;
    		  });
    	  });
    	  $('.close_panel').each(function (i, el) {
    		  $(el).click(function () {
    			  $(el).closest('.sublevel1').fadeOut();
    		  })
    	  });
    	  

    	  $("#topmenu").mmenu({
    	        // options
    		  	navbar: {
    		        add: false
    		    }
    	      }, {
    	   	   // configuration
    	   	   clone: true/*,
    	   	   offCanvas: {
    	               pageSelector: "#page"
    	   	   }*/
    	      }
    	  );
    	  
    	  $(window).resize(function () {
    		  masonry_container.masonry(masonry_options);
    	  });
    	  // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
